$dark-color: #191033;  // #3c1874
$light-color: #f9f9f8;
$highlight-color: #932432;//#932432
$highlight-color2:#087800;

$color-wahluek :$highlight-color2;
$card-color: #f0eded83;


body {
  background: $light-color;
  color: $dark-color;
  height: 100%;
  min-height: 100vh;
}

h6 {
  font-size: 0.8em;
}

.open {
  min-height: 100vh;
}

.planned {
  min-height: 100vh;
}

.inprogress {
  min-height: 100vh;
}

.done {
  min-height: 100vh;
}

.state {
  min-height: 100vh;
}

.state .modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Geplant{
  border-left: 1px solid $dark-color;
  border-right: 1px solid $dark-color;

}

.uek {
  color: $highlight-color !important;
}

.wahluek {
  color:$color-wahluek !important;
}

.card-body {
  padding: 5px;
}

.navigation{
  background-color: $card-color;
  color: $dark-color;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar {
  font-size: 1em;
  padding: 0;
}

.navbar a{
  color: $dark-color;
}

.navbar a:hover{
  color: $highlight-color;
}

.logotitle a {
  font-family: "Bungee", cursive;
  color: $dark-color;
  font-size: 2em;
  text-decoration: none;
}
.logotitle a img {
  height: 25px;
  margin-right: 5px;
  animation: rotate 20s infinite;
  animation-timing-function: linear;
  margin-top:-7px;
}

@keyframes rotate {
  100% {
    rotate: 360deg;
  }
}

.loading {
  font-family: "Bungee", cursive;
  color: $dark-color;
  font-size: 8em;
  margin: 20vh;
  animation: blink 1s infinite;
  background-image: url("../public/logo.svg");
  background-repeat: no-repeat;
  padding-top: 150px;
  min-height: 150px;
  min-width: 150px;
  left: 20vw;
  position: absolute;
  top: 10vh;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}

.loadinginfo {
  font-family: "Bungee", cursive;
  color: $dark-color;
  font-size: 2em;
  margin: 20vh;
  background-image: url("../public/logo.svg");
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-left: 100px;
  min-height: 150px;
  min-width: 150px;
}

.state h2 {
  background: $card-color;
  font-size: 1em;
  color: $highlight-color;
  margin: 10px 0;
  padding: 5px;

  text-align: center;
}

.card-title {
  font-size: 1em;
  margin-bottom: 3px;
}

.mb-2 {
  margin-bottom: -8px !important;
}



.card {
  color: $dark-color;
  margin: 2px 0px;
  background-color: $card-color;
  border-radius: 2px;

  display: grid;
  grid-template-columns: 15fr 1fr;
}

.dependency {
  float: right;
  width:16px;
  height: 8px;
  
}

#search {
  width: 300px;
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid $highlight-color;
}

.modal-body a {
  margin-left: 5px;
}

.detail {
  background-color: $light-color;
  color: $dark-color; 
  border-left: 2px solid $highlight-color;
}

.detail h2 {
  border-top: 1px solid $dark-color;
  font-size: 1em;
  color: $highlight-color;
  margin: 10px 0;
  padding: 5px;

}

.detail .content {
  font-size: 0.85em;
}

.detail .content h3 {
  font-size: 1.5em;
}

.detail .content input,
.detail .content textarea {
  font-size: 0.9em;
  border-radius: 0;
  
}

.detail .content select{
  background-color: $light-color;
  color: $dark-color;
  border: 1px solid $dark-color;
  border-radius: 1px;
  width: 100%;
}



.detail textarea{
  min-height: 200px;
}

.detail h4 {
  font-size: 1.1em;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

.detail h5 {
  font-size: 0.9em;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

.detail-overlay {
  position: fixed;
  top: 0px;
  left: 66vw;
  width: 34vw;
  height: calc(100vh - 0px);
  overflow: auto;

  animation-name: overlayIn;
  animation-duration: 0.5s;
  z-index: 10;
}

@keyframes overlayIn {
  from {
    left: 100vw;
  }
  to {
    left: 66vw;
  }
}

@media (max-width: 768px) {
  .detail-overlay {
    left: 10vw;
    width: 90vw;
    animation-name: overlayFullIn;
  animation-duration: 0.5s;
  z-index: 10;
  }
}

@keyframes overlayFullIn {
  from {
    left: 100vw;
  }
  to {
    left: 10vw;
  }
}


.closebtn {
  background-color: $highlight-color;
  color: $light-color;
  float: right;
  margin: 5px;
  margin-top: 20px;
  line-height: normal;
  border: 1px solid $highlight-color;
}

.closebtn:hover {
  border: 1px solid $highlight-color;
}

.draggable {
  content: "....";
  width: 10px;
  max-height: 50px;
  display: inline-block;
  overflow: hidden;
  line-height: 6px;
  padding: 3px 4px;
  cursor: move;
  vertical-align: middle;
  font-size: 15px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #f19fc8;
  text-shadow: 1px 0 1px black;
}
.draggable::after {
  content: ".. .. .. .. .. .. .. .. .. .. .. .. .. ..";
}

#message {
  position: fixed;
  background-color: $highlight-color2;
  height: 30px;
  padding: 1px;
  font-size: 1em;
  z-index: 10000;
  border-radius: 2px;
  width: calc(100vw - 20px);
  top: calc(100vh - 50px);
  left: 10px;
  text-align: center;
  display: none;
  animation: alternate 0.6s infinite;
  animation-name: textanimation;
}

#message.show {
  display: block;
}

input {
  background-color: #f7f7f7;
  border: none;
  border-bottom: 1px solid $dark-color;
}

textarea {
  font-size: 0.9em !important;
}

.detail form div{
  margin: 10px 0;
}

.btn-save {
  background-color: $highlight-color;
  margin: 15px 0;
  
  color: $light-color;
}

.btn-save:hover {
  background-color: $dark-color;
  color: $light-color;
  
}

span.startdate {
  font-size: 0.8em;
  color: #383838;
}
span.enddate {
  font-size: 0.8em;
  color: #383838;
}

@keyframes textanimation {
  from {
    color: rgb(5, 225, 34);
  }
  to {
    color: rgb(0, 0, 0);
  }
}

.login {
  margin: 60px auto;
  padding: 20px;
  border-radius: 10px;
}

.login a{ 
  color: $dark-color;
}

.login-img {
  margin-top: 25vh;
  margin-left: 12vw;
}

.login input {
  width: 100%;
}

.login button {
  width: 100%;
  background-color: $highlight-color;
  border: none;
  color: #fff;
  padding: 5px;
  transition: transform 0.3s ease-in-out;
}

.login button:hover {
  transform: scale(1.1);
}

.masterplan {
  width: 100%;
  font-size: 0.6em;
}

.masterplan tr {
  max-height:20px;
  height: 20px;
}

.masterplan td {
  border: #283747 1px solid;
}

.masterplan .wd {
min-width: 100px;
width: 100px;
}
.masterplan .wd button{
  width: 19px;
}

.modulwrapper {
  background-color: #b074e46c;
  position: absolute;
  left: 35px;
  padding: 0;
  width: 300px;
}

.modulwrapper .card {
  height: 100%;
  margin: 0;
}
.masterplanwrapper {
  position: relative;
  margin-top:50px;
}

.workdays {
  border: none;
}
.workday {
  background-color: #f3f3f300;
  border: none;
  width: 25px;
}

button.workday-set {
  background-color: #283747;
  color: $light-color;
  border: none;
  width: 25px;
}

button.saving {
  background-color: #5a9324;
}

.workdays .workday {
  border: none;
}

.holiday {
  background-color: #93243244;
}

.students {
  margin-top: 60px;
}

.studentcard {
  color: #283747;
  background-color: #93243244;
  padding: 8px;
  margin: 3px;
  font-size: 0.9em;
}

.studentcard h3 {
  font-weight: bold;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.studentcard p {
  padding: 0;
  margin: 0;
}

.module-studentname {
  border-bottom: 2px solid $highlight-color;
  color: $dark-color;
  padding: 3px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;;
}

.module-studentname .btn{
  float:right;
}
.module-container {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  border-bottom: 1px solid #283747;
  padding-bottom: 10px;
  font-size: 0.8em;
}

.module-title {
  font-weight: bold;
}

.module-overdue {
  color: $highlight-color;
  font-weight: bold;
}

.module-inprogress {
  color: #5a9324;
  font-weight: bold;
}

.studentselector {
  padding: 10px 50px;
  margin-top:5px; // to hide the border
  color: $light-color;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-1px); }
  20%, 40%, 60%, 80% { transform: translateX(1px); }
}
.error {
  color: $highlight-color;
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px 0; 
  animation: shake 0.5s 2;
}




